
import { defineComponent } from "vue";

export default defineComponent({
  name: 'AudioComponent',
  props: {
    audioPath: { type: String, default: '' },
    audioPartLabel: { type: String, default: '' },
  },
});
